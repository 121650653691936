var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "w-full p-6 bg-white rounded-lg shadow md:w-154 lg:w-154"
      },
      [
        _c("header", [
          this.new
            ? _c("div", [
                _c("h3", { staticClass: "text-xl font-medium text-black" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.webcams.addwebcam")))
                ]),
                _c("p", { staticClass: "text-base" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.webcams.addwebcamdescription")))
                ])
              ])
            : _c("div", [
                _c("h3", { staticClass: "text-xl font-medium text-black" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.webcams.editwebcam")))
                ]),
                _c("p", { staticClass: "text-base" }, [
                  _vm._v(
                    _vm._s(_vm.$t("resorts.webcams.editwebcamdescription"))
                  )
                ])
              ])
        ]),
        _c("LabelForm", [_vm._v(_vm._s(_vm.$t("resorts.webcams.provider")))]),
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "vs-radio",
                {
                  attrs: { "vs-name": "provider", "vs-value": "custom" },
                  model: {
                    value: _vm.provider,
                    callback: function($$v) {
                      _vm.provider = $$v
                    },
                    expression: "provider"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("resorts.webcams.standard")))]
              )
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "vs-radio",
                {
                  attrs: { "vs-name": "provider", "vs-value": "feratel" },
                  model: {
                    value: _vm.provider,
                    callback: function($$v) {
                      _vm.provider = $$v
                    },
                    expression: "provider"
                  }
                },
                [_vm._v("Feratel")]
              )
            ],
            1
          )
        ]),
        _vm.provider
          ? _c("div", [
              _vm.isCustom
                ? _c(
                    "div",
                    [
                      _c("LabelForm", [
                        _vm._v(_vm._s(_vm.$t("resorts.webcams.typeof")))
                      ]),
                      _c(
                        "vs-select",
                        {
                          staticClass:
                            "w-full border-grey-light md:w-1/2 lg:w-1/2",
                          model: {
                            value: _vm.type,
                            callback: function($$v) {
                              _vm.type = $$v
                            },
                            expression: "type"
                          }
                        },
                        _vm._l(_vm.webcamTypes, function(type, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: type.type, text: type.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c("LabelForm", [
                    _vm._v(_vm._s(_vm.$t("resorts.webcams.namewebcam")))
                  ]),
                  _c("vs-input", {
                    staticClass: "w-full border-grey-light",
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _vm.isCustom
                ? _c(
                    "div",
                    [
                      _c("LabelForm", [
                        _vm._v(_vm._s(_vm.$t("resorts.webcams.url")))
                      ]),
                      _c("vs-input", {
                        staticClass: "w-full border-grey-light",
                        model: {
                          value: _vm.url,
                          callback: function($$v) {
                            _vm.url = $$v
                          },
                          expression: "url"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCustom
                ? _c(
                    "div",
                    [
                      _c("LabelForm", [
                        _vm._v(_vm._s(_vm.$t("resorts.webcams.thumbnailurl")))
                      ]),
                      _c("vs-input", {
                        staticClass: "w-full border-grey-light",
                        model: {
                          value: _vm.thumbnail_url,
                          callback: function($$v) {
                            _vm.thumbnail_url = $$v
                          },
                          expression: "thumbnail_url"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isFeratel
                ? _c(
                    "div",
                    [
                      _c("LabelForm", [_vm._v("ID Feratel")]),
                      _c("vs-input", {
                        staticClass: "w-full border-grey-light",
                        model: {
                          value: _vm.feratel_id,
                          callback: function($$v) {
                            _vm.feratel_id = $$v
                          },
                          expression: "feratel_id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c("LabelForm", [
                    _vm._v(_vm._s(_vm.$t("resorts.webcams.latitude")))
                  ]),
                  _c("vs-input", {
                    staticClass: "w-full border-grey-light md:w-1/2 lg:w-1/2",
                    model: {
                      value: _vm.latitude,
                      callback: function($$v) {
                        _vm.latitude = $$v
                      },
                      expression: "latitude"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("LabelForm", [
                    _vm._v(_vm._s(_vm.$t("resorts.webcams.longitude")))
                  ]),
                  _c("vs-input", {
                    staticClass: "w-full border-grey-light md:w-1/2 lg:w-1/2",
                    model: {
                      value: _vm.longitude,
                      callback: function($$v) {
                        _vm.longitude = $$v
                      },
                      expression: "longitude"
                    }
                  })
                ],
                1
              ),
              _vm.latitude && _vm.longitude
                ? _c("div", { staticClass: "mt-8" }, [
                    _c(
                      "div",
                      { staticClass: "w-96" },
                      [
                        _c(
                          "GmapMap",
                          {
                            staticStyle: { width: "300px", height: "300px" },
                            attrs: {
                              center: {
                                lat: parseFloat(_vm.latitude),
                                lng: parseFloat(_vm.longitude)
                              },
                              zoom: 6,
                              "map-type-id": "satellite",
                              options: { streetViewControl: false }
                            }
                          },
                          [
                            _c("GmapMarker", {
                              ref: "myMarker",
                              attrs: {
                                position:
                                  _vm.google &&
                                  new _vm.google.maps.LatLng(
                                    parseFloat(_vm.latitude),
                                    parseFloat(_vm.longitude)
                                  )
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                [
                  _c("LabelForm", [_vm._v(_vm._s(_vm.$t("timeofyear")))]),
                  _c(
                    "vs-select",
                    {
                      staticClass: "w-full border-grey-light md:w-1/2 lg:w-1/2",
                      model: {
                        value: _vm.timeofyearSelected,
                        callback: function($$v) {
                          _vm.timeofyearSelected = $$v
                        },
                        expression: "timeofyearSelected"
                      }
                    },
                    _vm._l(this.timeOfYear, function(time, index) {
                      return _c("vs-select-item", {
                        key: index,
                        attrs: { value: time.value, text: time.name }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("LabelForm", [
                    _vm._v(_vm._s(_vm.$t("resorts.webcams.visibility")))
                  ]),
                  _c(
                    "vs-checkbox",
                    {
                      model: {
                        value: _vm.published,
                        callback: function($$v) {
                          _vm.published = $$v
                        },
                        expression: "published"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("resorts.webcams.published")))]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _c(
          "b-alert",
          {
            staticClass: "my-5 rounded-lg",
            attrs: { show: _vm.errorsOnSave.length > 0, variant: "danger" }
          },
          [
            _c(
              "ul",
              { staticClass: "list-none" },
              _vm._l(_vm.errorsOnSave, function(errorString, index) {
                return _c("li", { key: index }, [_vm._v(_vm._s(errorString))])
              }),
              0
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "mt-5" },
          [
            _c(
              "vs-button",
              {
                attrs: {
                  color: "primary",
                  type: "filled rounded-lg w-48 mr-10"
                },
                on: {
                  click: function($event) {
                    return _vm.saveWebcam()
                  }
                }
              },
              [
                this.new
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("resorts.webcams.save")))])
                  : _c("span", [
                      _vm._v(_vm._s(_vm.$t("resorts.webcams.update")))
                    ])
              ]
            ),
            _c(
              "vs-button",
              {
                staticClass: "w-48 rounded-lg cancel",
                attrs: { type: "cancel" },
                on: {
                  click: function($event) {
                    return _vm.$router.push(
                      "/resorts/" + _vm.resortUuid + "/webcams"
                    )
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("resorts.webcams.cancel")) + " ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }