<template>
    <div>
        <div class="w-full p-6 bg-white rounded-lg shadow md:w-154 lg:w-154">
            <header>
                <div v-if="this.isNew">
                    <h3 class="text-xl font-medium text-black">{{ $t("resorts.zones.addzone") }}</h3>
                    <p class="text-base">{{ $t("resorts.zones.addzonedescription") }}</p>
                </div>
                <div v-else>
                    <h3 class="text-xl font-medium text-black">{{ $t("resorts.zones.editzone") }}</h3>
                    <p class="text-base">{{ $t("resorts.zones.editzonedescription") }}</p>
                </div>
            </header>

            <LabelForm>{{ $t("resorts.webcams.name") }}</LabelForm>
            <vs-input class="w-full border-grey-light md:w-1/2 lg:w-1/2" v-model="name"/>

            <div class="mt-5">
                <vs-button color="primary" type="filled rounded-lg w-48 mr-10" @click="saveZone()">
                    <span v-if="this.isNew">{{ $t("resorts.webcams.save") }}</span>
                    <span v-else>{{ $t("resorts.webcams.update") }}</span>
                </vs-button>
                <vs-button type="cancel" class="w-48 rounded-lg cancel" @click="$router.push(`/resorts/${resortUuid}/zones`)">
                    {{ $t("resorts.webcams.cancel") }}
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>

import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import ZonesService from '@/modules/Resorts/Services/ZonesService'
import { v4 as uuidv4 } from 'uuid';
import loader from "@/modules/Shared/Mixins/loader";
import store from "@/modules/Shared/Store/store";

export default {
    name: 'zone-form',
    mixins: [loader],
    components: {
        LabelForm
    },
    data() {
        return {
            zone: null,
            resortUuid: '',
            name: null,
            isNew: true
        }
    },
    props: {
        zoneUuid: {
            type: String,
            default: null
        },
    },
    async beforeRouteEnter (to, from, next) {
        const zone = to.params.zoneUuid ?
            (await ZonesService.getZones(to.params.uuid, {})).find((zone) => zone.uuid === to.params.zoneUuid)
            : null
        await store.dispatch('setRouteElement', zone)
        next(vm => vm.zone = zone)
    },
    async created() {
        this.resortUuid = this.$route.params.uuid
        this.isNew = !this.zone

        if (!this.isNew) {
            this.name = this.zone.name
        }
    },

    methods: {
        async saveZone() {
            const payload = {
                name: this.name,
            }

            await this.loadAndNotify(async () => {
                if (!this.isNew) {
                    await ZonesService.editZone(this.resortUuid, this.zoneUuid, payload)
                } else {
                    await ZonesService.createZone(this.resortUuid, uuidv4(), payload)
                }

                setTimeout(() => {
                    this.$router.push(`/resorts/${this.resortUuid}/zones`);
                }, 1000)

            }, false)
        },
    }
}
</script>
