var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "w-full p-6 bg-white rounded-lg shadow md:w-154 lg:w-154"
      },
      [
        _c("header", [
          this.isNew
            ? _c("div", [
                _c("h3", { staticClass: "text-xl font-medium text-black" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.zones.addzone")))
                ]),
                _c("p", { staticClass: "text-base" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.zones.addzonedescription")))
                ])
              ])
            : _c("div", [
                _c("h3", { staticClass: "text-xl font-medium text-black" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.zones.editzone")))
                ]),
                _c("p", { staticClass: "text-base" }, [
                  _vm._v(_vm._s(_vm.$t("resorts.zones.editzonedescription")))
                ])
              ])
        ]),
        _c("LabelForm", [_vm._v(_vm._s(_vm.$t("resorts.webcams.name")))]),
        _c("vs-input", {
          staticClass: "w-full border-grey-light md:w-1/2 lg:w-1/2",
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        }),
        _c(
          "div",
          { staticClass: "mt-5" },
          [
            _c(
              "vs-button",
              {
                attrs: {
                  color: "primary",
                  type: "filled rounded-lg w-48 mr-10"
                },
                on: {
                  click: function($event) {
                    return _vm.saveZone()
                  }
                }
              },
              [
                this.isNew
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("resorts.webcams.save")))])
                  : _c("span", [
                      _vm._v(_vm._s(_vm.$t("resorts.webcams.update")))
                    ])
              ]
            ),
            _c(
              "vs-button",
              {
                staticClass: "w-48 rounded-lg cancel",
                attrs: { type: "cancel" },
                on: {
                  click: function($event) {
                    return _vm.$router.push(
                      "/resorts/" + _vm.resortUuid + "/zones"
                    )
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("resorts.webcams.cancel")) + " ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }