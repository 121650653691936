<template>
    <div>
        <div class="w-full p-6 bg-white rounded-lg shadow md:w-154 lg:w-154">
            <header>
                <div v-if="this.new">
                    <h3 class="text-xl font-medium text-black">{{ $t("resorts.webcams.addwebcam") }}</h3>
                    <p class="text-base">{{ $t("resorts.webcams.addwebcamdescription") }}</p>
                </div>
                <div v-else>
                    <h3 class="text-xl font-medium text-black">{{ $t("resorts.webcams.editwebcam") }}</h3>
                    <p class="text-base">{{ $t("resorts.webcams.editwebcamdescription") }}</p>
                </div>
            </header>

            <LabelForm>{{ $t("resorts.webcams.provider") }}</LabelForm>
            <ul>
                <li>
                    <vs-radio v-model="provider" vs-name="provider" vs-value="custom">{{ $t("resorts.webcams.standard") }}</vs-radio>
                </li>
                <li>
                    <vs-radio v-model="provider" vs-name="provider" vs-value="feratel">Feratel</vs-radio>
                </li>
            </ul>

            <div v-if="provider">
                <div v-if="isCustom">
                    <LabelForm>{{ $t("resorts.webcams.typeof") }}</LabelForm>
                    <vs-select class="w-full border-grey-light md:w-1/2 lg:w-1/2" v-model="type">
                        <vs-select-item v-for="(type, index) in webcamTypes" :key="index" :value="type.type" :text="type.name" />
                    </vs-select>
                </div>

                <div>
                    <LabelForm>{{ $t("resorts.webcams.namewebcam") }}</LabelForm>
                    <vs-input class="w-full border-grey-light" v-model="name"/>
                </div>

                <div v-if="isCustom">
                    <LabelForm>{{ $t("resorts.webcams.url") }}</LabelForm>
                    <vs-input class="w-full border-grey-light" v-model="url"/>
                </div>

                <div v-if="isCustom">
                    <LabelForm>{{ $t("resorts.webcams.thumbnailurl") }}</LabelForm>
                    <vs-input class="w-full border-grey-light" v-model="thumbnail_url"/>
                </div>

                <div v-if="isFeratel">
                    <LabelForm>ID Feratel</LabelForm>
                    <vs-input class="w-full border-grey-light" v-model="feratel_id"/>
                </div>

                <div>
                    <LabelForm>{{ $t("resorts.webcams.latitude") }}</LabelForm>
                    <vs-input class="w-full border-grey-light md:w-1/2 lg:w-1/2" v-model="latitude"/>
                </div>

                <div>
                    <LabelForm>{{ $t("resorts.webcams.longitude") }}</LabelForm>
                    <vs-input class="w-full border-grey-light md:w-1/2 lg:w-1/2" v-model="longitude"/>
                </div>

                <div v-if="latitude && longitude" class="mt-8">
                    <div class="w-96">
                        <GmapMap :center="{lat: parseFloat(latitude), lng: parseFloat(longitude)}" :zoom="6" map-type-id="satellite" style="width: 300px; height: 300px" :options="{streetViewControl: false}">
                            <GmapMarker ref="myMarker" :position="google && new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude))" />
                        </GmapMap>
                    </div>
                </div>

                <div>
                    <LabelForm>{{ $t("timeofyear") }}</LabelForm>
                    <vs-select class="w-full border-grey-light md:w-1/2 lg:w-1/2" v-model="timeofyearSelected">
                        <vs-select-item v-for="(time, index) in this.timeOfYear" :key="index" :value="time.value" :text="time.name" />
                    </vs-select>
                </div>

                <div>
                    <LabelForm>{{ $t("resorts.webcams.visibility") }}</LabelForm>
                    <vs-checkbox v-model="published">{{ $t("resorts.webcams.published") }}</vs-checkbox>
                </div>
            </div>

            <b-alert :show="errorsOnSave.length > 0" variant="danger" class="my-5 rounded-lg">
                <ul class="list-none">
                    <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
                </ul>
            </b-alert>

            <div class="mt-5">
                <vs-button color="primary" type="filled rounded-lg w-48 mr-10" @click="saveWebcam()">
                    <span v-if="this.new">{{ $t("resorts.webcams.save") }}</span>
                    <span v-else>{{ $t("resorts.webcams.update") }}</span>
                </vs-button>
                <vs-button type="cancel" class="w-48 rounded-lg cancel" @click="$router.push(`/resorts/${resortUuid}/webcams`)">
                    {{ $t("resorts.webcams.cancel") }}
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { gmapApi } from 'vue2-google-maps'
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import WebcamService from '@/modules/Resorts/Services/WebcamService'
import store from "@/modules/Shared/Store/store";

export default {
    name: 'webcam-form',
    components: {
        LabelForm
    },
    data() {
        return {
            resortUuid: '',
            errorsOnSave:[],

            provider: null,
            type: null,
            name: null,
            url: null,
            thumbnail_url: null,
            feratel_id: null,
            latitude: null,
            longitude: null,
            timeofyearSelected: 'always',
            published: false,
        }
    },
    async beforeRouteEnter (to, from, next) {
        const webcam = to.params.webcam === null || Object.keys(to.params.webcam).length === 0 ? null : to.params.webcam
        await store.dispatch('setRouteElement', webcam)
        next()
    },
    props: {
        webcamUuid: String,
        webcam: {
            type: Object,
            default: () => {}
        }
    },
    async created() {
        this.resortUuid = this.$route.params.uuid

        if (!this.new) {
            this.provider = this.webcam.provider
            this.type = this.webcam.type
            this.name = this.webcam.name
            this.url = this.webcam.url
            this.thumbnail_url = this.webcam.thumbnail_url
            this.feratel_id = this.webcam.feratel_id
            this.latitude = this.webcam.latitude
            this.longitude = this.webcam.longitude
            this.timeofyearSelected = this.webcam.timeofyear
            this.published = this.webcam.published
        }
    },
    computed: {
        google: gmapApi,
        ...mapGetters( ['timeOfYear'] ),
        ...mapGetters( 'resorts', ['webcamTypes'] ),
        isCustom() {
            return this.provider == 'custom'
        },
        isFeratel() {
            return this.provider == 'feratel'
        },
        new() {
            return JSON.stringify(this.webcam) === JSON.stringify({}) || this.webcam == undefined
        }
    },
    methods: {
        async saveWebcam() {
            const payload = {
                provider: this.provider,
                type: this.type,
                name: this.name,
                url: this.url,
                thumbnail_url: this.thumbnail_url,
                feratel_id: this.feratel_id,
                latitude: this.latitude,
                longitude: this.longitude,
                timeofyear: this.timeofyearSelected,
                published: this.published
            }
            let response;

            if (!this.hasErrors()) {
                if (this.webcam && !this.new) {
                    response = await WebcamService.saveWebcam(this.resortUuid, this.webcamUuid, payload)
                } else {
                    response = await WebcamService.createWebcam(this.resortUuid, payload)
                }

                if (response.code == 200) {
                    const text = this.new ? this.$t("resorts.webcams.created.ok") : this.$t("resorts.webcams.updated.ok")
                    this.$vs.notify( { text: text, color: 'success', position:'top-right' } )
                    setTimeout(() => {
                        this.$router.push(`/resorts/${this.resortUuid}/webcams`);
                    }, 1000)
                } else {
                    this.$vs.notify( { text: this.new ? this.$t("resorts.webcams.created.ko") : this.$t("resorts.webcams.updated.ko"), color: 'danger', position:'top-right'})
                }
            }
        },
        hasErrors() {
            this.errorsOnSave = []

            if (!this.provider) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.providerempty")]
            }

            if (!this.name) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.nameempty")]
            }

            if (!this.latitude) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.latitudeempty")]
            }

            if (!this.longitude) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.longitudeempty")]
            }

            if (!this.timeofyearSelected) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.timeofyearempty")]
            }

            if (this.isCustom) {
                if (!this.type) {
                    this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.typeempty")]
                }

                if (!this.url) {
                    this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.urlempty")]
                }

                if (!this.thumbnail_url) {
                    this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.thumbnailurlempty")]
                }
            }

            if (this.isFeratel) {
                if (!this.feratel_id) {
                    this.errorsOnSave = [...this.errorsOnSave, this.$t("resorts.webcams.idferatelempty")]
                }
            }

            return this.errorsOnSave.length !== 0;
        }
    }
}
</script>
